import React from 'react'
import * as s from 'shared/diff/schema'

import { Column, CellProps, TableRowComponentProps } from 'react-table'
import { Table, RowComponentDefault } from 'shared/components/Table'
import { Op } from 'shared/diff/Op'
import { DiffValue } from 'shared/diff/DiffValue'
import { DiffArrayPrimitive } from 'shared/diff/DiffArrayPrimitive'
import Container from 'shared/components/Container'
import TableHeaderContent from 'shared/components/Table/TableHeaderContent'
import { StoryRolesForOp } from 'shared/diff/components/StoryRolesForOp'

import {
    DiffableFeatureStoryline,
    FeatureStoryline,
    FeatureStorylineDiffSchema,
    storylineCompareByComponent,
    toDiffableFeatureStoryline,
} from 'codecs/storyline/FeatureStoryline'
import Text from 'shared/components/Text'
import { Pill } from 'shared/components/Table/TablePills'
import { useWIPDiff } from 'shared/resource/ResourceMachineProvider'

type FeatureStorylinesDiffOps = s.ArrayDiffOps<DiffableFeatureStoryline>

const FeatureStorylinesDiffsTableColumns: Column<FeatureStorylinesDiffOps>[] = [
    {
        Header: 'Component',
        accessor: op => op.value.storylineComponent,
        sortType: ({ original: storylineA }, { original: storylineB }) => {
            return storylineCompareByComponent(storylineA.value, storylineB.value)
        },
        Cell: ({ row: { original: op } }: CellProps<FeatureStorylinesDiffOps>) => {
            return s.isSchemaDiff(op) ? (
                <DiffValue diff={op.ops.storylineComponent} map={sc => sc?.label} />
            ) : (
                <Op type={op.type}>{op.value.storylineComponent?.label ?? '<Empty>'}</Op>
            )
        },
        width: 80,
    },
    {
        Header: 'Logline',
        accessor: op => op.value.storylineLogLine,
        Cell: ({ row: { original: op } }: CellProps<FeatureStorylinesDiffOps>) => {
            return s.isSchemaDiff(op) ? (
                <DiffValue diff={op.ops.storylineLogLine} />
            ) : (
                <Op type={op.type}>{op.value.storylineLogLine}</Op>
            )
        },
        width: 300,
    },
    {
        Header: 'Lead Character',
        accessor: op => op.value.storylineLeadCharacter,
        Cell: ({ row: { original: op } }: CellProps<FeatureStorylinesDiffOps>) => {
            return s.isSchemaDiff(op) ? (
                <DiffArrayPrimitive diff={op.ops.storylineLeadCharacter} />
            ) : (
                <div>
                    {op.value.storylineLeadCharacter.map((name, i) => (
                        <Pill key={name + String(i)} diffType={op.type}>
                            {name}
                        </Pill>
                    ))}
                </div>
            )
        },
    },
    {
        Header: 'Storyline Elements',
        accessor: op => op.value.storylineArchetypes,
        Cell: ({ row: { original: op } }: CellProps<FeatureStorylinesDiffOps>) => {
            return s.isSchemaDiff(op) ? (
                <DiffArrayPrimitive diff={op.ops.storylineArchetypes} />
            ) : (
                <div>
                    {op.value.storylineArchetypes.map((label, i) => (
                        <Pill key={label + String(i)} diffType={op.type}>
                            {label}
                        </Pill>
                    ))}
                </div>
            )
        },
    },
]
const initialStateBase = {
    sortBy: [{ id: 'Component' }, { id: 'Description' }],
}

export const FeatureStorylinesDiff: React.FC = (): React.ReactElement => {
    const { ops } = useWIPDiff(
        'storylines',
        s.array(FeatureStorylineDiffSchema, 'storylineId'),
        storylines => (storylines as FeatureStoryline[]).map(toDiffableFeatureStoryline),
    )
    const expanded = React.useMemo(() => {
        let expanded: Record<string, boolean> = {}
        const len = ops.length
        for (let i = 0; i < len; i++) {
            const op = ops[i]
            if (s.isSchemaDiff(op)) {
                if (op.ops.storyRoles.changed) {
                    expanded[i] = true
                }
            } else if (!s.isDiffOpSame(op)) {
                expanded[i] = true
            }
        }
        return expanded
    }, [ops])
    const initialState = React.useMemo(() => {
        return { ...initialStateBase, expanded }
    }, [expanded])

    return (
        <>
            <Container alignItems="center">
                <TableHeaderContent>
                    <Text as="h3" size="2" my="2">
                        Storylines
                    </Text>
                </TableHeaderContent>
            </Container>
            <Table<FeatureStorylinesDiffOps>
                {...{
                    columns: FeatureStorylinesDiffsTableColumns,
                    RowComponent: CustomRow,
                    initialState,
                    data: ops,
                    emptyMessage: 'No changes',
                    getCellProps: () => ({
                        removeBottomBorderRadius: true,
                    }),
                    getRowProps: ({ original: op }) => ({
                        diffType: s.isSchemaDiff(op) ? 'same' : op.type,
                    }),
                }}
            />
        </>
    )
}
export default React.memo(FeatureStorylinesDiff)

function CustomRow(props: TableRowComponentProps<FeatureStorylinesDiffOps>): React.ReactElement {
    return (
        <>
            <RowComponentDefault {...props} />
            <StoryRolesForOp {...props} />
        </>
    )
}
