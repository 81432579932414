import React from 'react'
import { FormikProps, FieldArray } from 'formik'
import ErrorMessage from 'shared/components/ErrorMessage'
import { transparentize } from 'polished'

import { FeatureStorylineFormValue, FeatureStoryline } from 'codecs/storyline/FeatureStoryline'
import { StoryRole, StoryRoleFormValue } from 'codecs/storyline/StoryRole'
import { PropsToJSON } from 'shared/components/dev/PropsToJSON'

import styled from 'shared/theme'
import StoryroleEdit from 'shared/components/Storyrole/StoryroleEdit'
import { FormikInput } from 'shared/components/Input'
import Text from 'shared/components/Text'
import TextualButton from 'shared/components/TextualButton'
import Button from 'shared/components/Button'
import CellLabel from 'shared/components/CellLabel'
import Container from 'shared/components/Container'
import { ReactComponent as TrashCanIcon } from 'shared/components/Icons/trash-can-icon.svg'
import DeleteButton from 'shared/components/DeleteButton'
import { AlertDialog, AlertDialogLabel, AlertDialogButtons } from 'shared/components/AlertModal'
import PublishErrors from 'shared/components/PublishErrors'

import { useOnClickOutsideRef } from 'shared/hooks/useOnClickOutside'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { generateDisneyId } from 'shared/hooks/useDisneyId'

import StorylineArchetypeSelect from 'shared/components/StorylineArchetypeSelect'
import StorylineLeadCharacterSelect from './StorylineLeadCharacterSelect'
import StorylineComponentSelect from './StorylineComponentSelect'
import { useStorylinesContext } from '../index'
import { usePublishErrorManager } from 'shared/resource/ResourceMachineProvider'
import { useCharacters } from 'shared/characters/CharactersProvider'

export const StorylineTableLayout = styled.div<{
    hideNumberColumn?: boolean
    hasErrors?: boolean
}>`
    padding: 0.5rem 1rem 1rem;
    display: grid;
    column-gap: 1rem;
    grid-template-columns: ${props =>
        props.hideNumberColumn ? '160px 6fr 3fr 3fr' : 'minmax(5px, 18px) 160px 6fr 3fr 3fr'};
    align-items: start;
    ${props => props.hasErrors && `background-color: ${props.theme.colors.backgroundError};`}
`

const StyledContainer = styled.div`
    background-color: ${props => props.theme.colors.activeField};
    box-shadow: 0 4px 8px 0 ${props => transparentize('0.7', props.theme.colors.textSecondary)};
    border: 1px solid ${props => props.theme.colors.gray};
    border-radius: 5px;
    margin-bottom: 0.5rem;
    position: relative;
    width: 100%;
`

const StyledText = styled(Text).attrs<{}>({
    size: 5,
})`
    line-height: 3rem;
`

const ButtonsContaier = styled(Container).attrs({
    justifyContent: 'flex-end',
    p: '2',
})`
    border-top: 1px solid ${props => props.theme.colors.border};
`

const StorylineFormNode: React.FC<
    FormikProps<FeatureStorylineFormValue> & {
        index?: number
    }
> = ({ index, submitForm, values: storylineValue, errors }): React.ReactElement => {
    const { editIdentifier, remove, cancelEditCreate } = useStorylinesContext()

    const isStorylineNew = editIdentifier !== storylineValue.storylineId
    const characters = useCharacters()

    const publishErrors = usePublishErrorManager().getErrors(
        (u): u is FeatureStoryline => u.storylineId === editIdentifier,
    )

    const [confirm, showConfirmModal] = React.useState(false)

    const cancelRef = React.createRef<HTMLButtonElement>()

    const ref = useOnClickOutsideRef<HTMLTableRowElement>(
        useEventCallback(() => {
            // autosave only when editing existing and
            // no DeleteConfirmationModal is opend
            if (!isStorylineNew && !confirm) {
                submitForm()
            }
        }),
    )

    return (
        <StyledContainer ref={ref}>
            <StorylineTableLayout
                hideNumberColumn={isStorylineNew}
                hasErrors={publishErrors.length > 0}
            >
                {!isStorylineNew && (
                    <>
                        <DeleteButton onClick={() => showConfirmModal(true)}>
                            <TrashCanIcon />
                        </DeleteButton>
                        {confirm && (
                            <AlertDialog leastDestructiveRef={cancelRef}>
                                <AlertDialogLabel>Delete this storyline?</AlertDialogLabel>
                                <AlertDialogButtons>
                                    <Button variant="danger" onClick={() => remove(storylineValue)}>
                                        Delete
                                    </Button>
                                    <TextualButton
                                        onClick={() => showConfirmModal(false)}
                                        ref={cancelRef}
                                        variant="primary"
                                    >
                                        Cancel
                                    </TextualButton>
                                </AlertDialogButtons>
                            </AlertDialog>
                        )}
                    </>
                )}
                {!isStorylineNew && index !== undefined && (
                    <div style={{ textAlign: 'center' }}>
                        <CellLabel>N</CellLabel>
                        <StyledText>{index + 1}</StyledText>
                    </div>
                )}
                <div>
                    <CellLabel>Component</CellLabel>
                    <StorylineComponentSelect name="storylineComponent" />
                    <ErrorMessage name="storylineComponent" />
                </div>
                <div>
                    <CellLabel>Logline</CellLabel>
                    <FormikInput name="storylineLogLine" fastValidate={true} multiline />
                    <ErrorMessage name="storylineLogLine" />
                </div>
                <div>
                    <CellLabel>Lead Character</CellLabel>
                    <StorylineLeadCharacterSelect
                        name="storylineLeadCharacter"
                        characters={characters}
                    />
                    <ErrorMessage name="storylineLeadCharacter" />
                </div>
                <div>
                    <CellLabel>Storyline Elements</CellLabel>
                    <StorylineArchetypeSelect name="storylineArchetypes" />
                    <ErrorMessage name="storylineArchetypes" />
                </div>
                <PublishErrors errors={publishErrors} />
            </StorylineTableLayout>

            <div>
                <FieldArray name="storyRoles">
                    {({ push, remove }) => (
                        <>
                            {storylineValue.storyRoles!.map((storyRole, index) => (
                                <StoryroleEdit
                                    storyRole={storyRole as StoryRole}
                                    key={storyRole.storyRoleId}
                                    index={index}
                                    remove={remove}
                                    characters={characters}
                                />
                            ))}
                            <ButtonsContaier>
                                <Button
                                    variant="outline"
                                    onClick={() => {
                                        push(createNewStoryRole())
                                    }}
                                >
                                    Add storyrole
                                </Button>
                            </ButtonsContaier>
                        </>
                    )}
                </FieldArray>

                <ButtonsContaier>
                    <TextualButton
                        weight="bold"
                        mr="1"
                        onClick={() => cancelEditCreate(storylineValue)}
                    >
                        Cancel
                    </TextualButton>

                    <Button
                        onClick={() =>
                            // hack for proper formik states
                            Promise.resolve().then(() => submitForm())
                        }
                    >
                        {isStorylineNew ? 'Add' : 'Save'}
                    </Button>
                </ButtonsContaier>
            </div>
            <PropsToJSON errors={errors} />
            <PropsToJSON values={storylineValue} />
        </StyledContainer>
    )
}

function createNewStoryRole(): StoryRoleFormValue {
    return {
        character: [],
        attributes: [],
        storyRoleId: generateDisneyId(),
    }
}

export default StorylineFormNode
