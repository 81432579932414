import axios from 'axios'
import axiosRetry from 'axios-retry'

import { MyIDUser } from '@genome-web-forms/common/auth'
import { AxiosRequestConfigExtended } from '@genome-web-forms/common/api'

if (process.env.NODE_ENV !== 'test') {
    axiosRetry(axios, {
        retries: 3,
        retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000),
        retryCondition(error) {
            return error.response && error.response.status
                ? error.response.status.toString().startsWith('5')
                : false
        },
    })
}

export function authGWF<DataSent = any>(
    user: MyIDUser,
    config: AxiosRequestConfigExtended<DataSent>,
): AxiosRequestConfigExtended {
    return {
        ...config,
        headers: {
            ...(config.headers || {}),
            'x-gsso-myid': user['x-gsso-myid'],
            'x-gsso-myid-userdata': user['x-gsso-myid-userdata'],
        },
    }
}

export function authGWFWOUserData<DataSent = any>(
    user: MyIDUser,
    config: AxiosRequestConfigExtended<DataSent>,
): AxiosRequestConfigExtended {
    return {
        ...config,
        headers: {
            ...(config.headers || {}),
            'x-gsso-myid': user['x-gsso-myid'],
        },
    }
}

export function authCWR<DataSent = any>(
    user: MyIDUser,
    config: AxiosRequestConfigExtended<DataSent>,
): AxiosRequestConfigExtended {
    return {
        ...config,
        headers: {
            ...(config.headers || {}),
            Authorization: 'BEARER ' + user['cwr-api-authz-token'],
        },
    }
}
